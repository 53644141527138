import { graphql, Link } from "gatsby"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../../../layouts/CSLayout"

export default function CS({ data }) {
  return (
    <Layout>
      <div className="container mx-auto py-5">
        <div className="row bg-white shadow rounded-xl p-3">
          <div className="col-lg-8 align-self-center">
            <div className="h-100">
              <div className="card-body">
                <div className="d-flex justify-content-center">
                  <StaticImage
                    height={200}
                    src="../../../images/CS_logo.webp"
                    alt="Logo of CS"
                  />
                </div>
                <div className="cardTitle">About CS</div>
                <div className="card-text">
                  We Are the Home for Computer Science and Engineering Leaders.
                  <br />
                  <br />
                  The IEEE Computer Society is the premier source for
                  information, inspiration, and collaboration in computer
                  science and engineering.Connecting members worldwide, the
                  Computer Society empowers the people who advance technology by
                  delivering tools for individuals at all stages of their
                  professional careers.Our trusted resources include
                  international conferences, peer-reviewed publications, a
                  robust digital library, globally recognized standards, and
                  continuous learning opportunities.
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 align-self-center">
            <div className="card-body">
              <div className="cardTitle">Vision</div>
              <div className="card-text">
                To be the leading provider of technical information, community
                services, and personalized services to the world’s computing
                professionals.
              </div>
            </div>

            <div className="card-body">
              <div className="cardTitle">Field of Interest</div>
              <div className="card-text">
                The scope of the Society shall encompass all aspects of theory,
                design, practice, and application relating to computer and
                information processing science and technology.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto my-4">
        <div className="heading">
          Execom
          <div className="underline"></div>
        </div>
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 justify-content-center">
          <div className="col d-flex flex-column align-items-center my-4">
            <StaticImage
              height={180}
              className="rounded-xl m-1"
              src="../../../images/execom/2023-24/CS - Chair - Chaitanya Shaji.jpg"
              alt="Chaitanya "
            />
            <p className="fs-5 fw-bold mb-0 mt-1">Chaitanya Shaji</p>
            <p>Chairperson</p>
          </div>
          <div className="col d-flex flex-column align-items-center my-4">
            <StaticImage
              height={180}
              className="rounded-xl m-1"
              src="../../../images/execom/2023-24/CS - Vice Chair - Sravan.jpg"
              alt="Sravan"
            />
            <p className="fs-5 fw-bold mb-0 mt-1">Sravan Jyothis</p>
            <p>Vice Chairperson</p>
          </div>
          <div className="col d-flex flex-column align-items-center my-4">
            <StaticImage
              height={180}
              className="rounded-xl m-1"
              src="../../../images/execom/2023-24/CS - WiC - Sandra P.jpg"
              alt="Sandra"
            />
            <p className="fs-5 fw-bold mb-0 mt-1">Sandra P</p>
            <p>Women in Computing</p>
          </div>
        </div>
      </div>

      <div className="container my-5">
        <div className="heading">
          Events
          <div className="underline"></div>
        </div>
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 align-items-center justify-content-center my-4">
          {data.allMarkdownRemark.edges?.map(el => (
            <Link
              key={el.node.frontmatter.title}
              className="col card my-2 shadow"
              to={`../../reg/${el.node.frontmatter.slug}`}
              style={{ maxWidth: "18rem" }}
            >
              <div>
                <GatsbyImage
                  image={getImage(el.node.frontmatter.image)}
                  alt={el.node.frontmatter.title}
                  className="card-img"
                />
              </div>
              <div className="card-body d-flex felx-column center">
                <div className="fw-bold text-center text-dark">
                  {el.node.frontmatter.title}
                </div>
              </div>
            </Link>
          ))}
        </div>
        <div className="d-flex justify-content-center my-4">
          <Link className="btn btn-primary" to="/reg">
            View More Events
          </Link>
        </div>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query CS {
    allMarkdownRemark(
      filter: { frontmatter: { by: { regex: "/CS/i" } } }
      limit: 3
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            date
            slug
            by
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`
